import { isValid, parseISO } from 'date-fns';

export const FORMATS = {
  FIRSTDAYOFWEEK: 1,
  DAYNAMES: [
    'Søndag',
    'Mandag',
    'Tirsdag',
    'Onsdag',
    'Torsdag',
    'Fredag',
    'Lørdag'
  ],
  DAYNAMESSHORT: ['Søn', 'Man', 'Tir', 'Ons', 'Tor', 'Fre', 'Lør'],
  DAYNAMESMIN: ['S', 'M', 'T', 'O', 'T', 'F', 'L'],
  MONTHNAMES: [
    'Januar',
    'Februar',
    'Mars',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Desember'
  ],
  MONTHNAMESSHORT: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'Mai',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Okt',
    'Nov',
    'Des'
  ],
  TODAY: 'I dag',
  CLEAR: 'Tøm'
};

export const monthYear = (pmnd, short = false) => {
  if (!pmnd) {
    return 'Ukjent';
  }

  const yearMonth = pmnd.toString();

  if (yearMonth.length === 2) {
    return `20${yearMonth}`;
  }

  const year = yearMonth.substring(0, 4);
  const month = parseInt(yearMonth.substring(4, 6));

  if (isNaN(month)) {
    return year;
  } else if (short) {
    return `${FORMATS.MONTHNAMESSHORT[month - 1]} ${year}`;
  } else {
    return `${FORMATS.MONTHNAMES[month - 1]} ${year}`;
  }
};

export const manedAr = (dateStr) => {
  if (!dateStr || typeof dateStr !== 'string') {
    return 'Ukjent';
  }

  const [ar, maned] = dateStr.split('-');

  if (!maned) {
    if (ar.length === 2) {
      return `20${ar}`;
    }

    return ar;
  }

  return `${FORMATS.MONTHNAMES[maned - 1]} ${ar}`;
};

export const datoToText = (dato, dayname = true, short = false) => {
  // dato eksempel int 20220105
  if (!dato) {
    return '';
  }
  const dateString = dato.toString();

  const monthAndYear = monthYear(dateString, short);

  if (dateString.length === 8) {
    const parseDato = parseISO(dateString);

    if (!isValid(parseDato)) {
      return '';
    }

    const day = parseInt(dateString.substring(6));

    if (dayname) {
      return `${FORMATS.DAYNAMESSHORT[parseDato.getDay()]} ${day}. ${monthAndYear}`;
    } else {
      return `${day}. ${monthAndYear}`;
    }
  } else {
    return monthAndYear;
  }
};

export const datoFormat = (dato) => {
  // dato eksempel int 20220105
  if (!dato) {
    return '';
  }
  // convert 20220115 to 15.01.2025
  const dateString = dato.toString();

  if (dateString.length === 8) {
    const parseDato = parseISO(dateString);

    if (!isValid(parseDato)) {
      return '';
    }

    const day = parseInt(dateString.substring(6));
    const month = parseInt(dateString.substring(4, 6));

    return `${day.toString().padStart(2, '0')}.${month.toString().padStart(2, '0')}.${dateString.substring(0, 4)}`;
  } else {
    return '';
  }
};

export const regToText = (registrert, dayname = true) => {
  // Eksempel registrert = "2022-01-11 19:33:03"
  if (!registrert) {
    return '';
  }
  const [yearMonthDay, time] = registrert.split(' ');

  const collapse = yearMonthDay.split('-').join('');
  const dato = datoToText(collapse, dayname);
  const timeStr = time || '';

  return `${dato} ${timeStr}`;
};

export const dateToText = (date, dayname = true) => {
  if (!date) {
    return '';
  }

  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  const monthAndYear = monthYear(`${year}${month.toString().padStart(2, '0')}`);

  if (dayname) {
    return `${FORMATS.DAYNAMESSHORT[date.getDay()]} ${day}. ${monthAndYear}`;
  } else {
    return `${day}. ${monthAndYear}`;
  }
};

export const dateToReg = (date) => {
  if (!date) {
    return '';
  }

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');

  return `${year}${month}${day}`;
};

export const parseManed = (maned) => maned.split('-').join('');
